<template>
    <div>
        <card-header title="Account Settings" icon="fa-user-cog"/>

        <card-body>
            <card-list>
                <subheader-list-item icon="fa-user" title="Information"/>

                <container-list-item padded>
                    <div class="columns">
                        <div class="column is-6">
                            <b-field label="First Name" label-position="inside">
                                <b-input v-model="user.first_name" required></b-input>
                            </b-field>
                        </div>
                        <div class="column is-6">
                            <b-field label="Last Name" label-position="inside">
                                <b-input v-model="user.last_name"></b-input>
                            </b-field>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <b-field label="Username" label-position="inside">
                                <b-input type="email" v-model="user.username" required></b-input>
                            </b-field>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <b-field label="Password (Set to Change)" label-position="inside">
                                <b-input v-model="user.password"></b-input>
                            </b-field>
                        </div>
                    </div>
                </container-list-item>
            </card-list>
        </card-body>

        <card-footer class="pt-3 pb-3 has-text-right">
            <b-button
                type="is-primary"
                size="is-small"
                @click="submit"
                :disabled="!user.first_name || !user.last_name || !user.username"
            ><i class="fas fa-check mr-2"></i>Update
            </b-button>
        </card-footer>
    </div>
</template>

<script>
    import CardHeader from "@/TIER/components/CardHeader";
    import CardBody from "@/TIER/components/CardBody";
    import {client as http} from '@/http_client';
    import CardFooter from "@/TIER/components/CardFooter";
    import {mapGetters} from "vuex";
    import CardList from "@/TIER/components/CardList";
    import SubheaderListItem from "@/TIER/components/SubheaderListItem";
    import ContainerListItem from "@/TIER/components/ContainerListItem";

    export default {
        components: {ContainerListItem, SubheaderListItem, CardList, CardFooter, CardBody, CardHeader},
        props: ['card', 'props'],
        data() {
            return {
                user: {
                    first_name: null,
                    last_name: null,
                    username: null,
                    password: null
                }
            }
        },
        computed: {
            ...mapGetters(['currentUser'])
        },
        methods: {
            submit: function() {
                this.$emit('loading', true);
                http.put('/api/portal_users/' + this.currentUser.id, this.user).then(response => {
                    this.$closeCard(this.card);
                });
            }
        },
        mounted: function() {
            this.user.first_name = this.currentUser.first_name;
            this.user.last_name = this.currentUser.last_name;
            this.user.username = this.currentUser.username;
        }
    }
</script>
